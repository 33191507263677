@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  accent-color: #f6b32a;
}

@layer utilities {
  .bg-gradient {
    background-color: #d84132;
    background-image: linear-gradient(
      43deg,
      #4158d0 0%,
      #c850c0 46%,
      #ffcc70 100%
    );
  }

  .bg-tblack {
    background-color: rgba(0, 0, 0, 0.35);
  }

  .bg-overlay {
    background-color: rgba(0, 0, 0, 0.35);
    color: #fff;
  }

  .btn-pillow {
    @apply px-3 py-1 text-xs bg-gray-200 rounded-xl;
  }

  .btn-pillow-i {
    @apply btn-pillow;
    background-color: rgba(0, 0, 0, 0.35);
    color: #fff;
  }

  .bg-step-1 {
    background-image: url("../public/backgrounds/step_1.jpg");
    background-repeat: no-repeat;
  }
  .bg-step-2 {
    background-image: url("../public/backgrounds/step_2.jpg");
    background-repeat: no-repeat;
  }
  .bg-step-3 {
    background-image: url("../public/backgrounds/step_3.jpg");
    background-repeat: no-repeat;
  }
  .bg-step-4 {
    background-image: url("../public/backgrounds/step_4.jpg");
    background-repeat: no-repeat;
  }
  .bg-step-5 {
    background-image: url("../public/backgrounds/step_5.jpg");
    background-repeat: no-repeat;
  }
  .bg-step-6 {
    background-image: url("../public/backgrounds/step_6.jpg");
    background-repeat: no-repeat;
  }
  .bg-step-7 {
    background-image: url("../public/backgrounds/step_7.jpg");
    background-repeat: no-repeat;
  }
  .bg-step-8 {
    background-image: url("../public/backgrounds/step_8.jpg");
    background-repeat: no-repeat;
  }
  .bg-step-9 {
    background-image: url("../public/backgrounds/step_9.jpg");
    background-repeat: no-repeat;
  }
  .bg-step-10 {
    background-image: url("../public/backgrounds/step_10.jpg");
    background-repeat: no-repeat;
  }

  /**
  * Company bg
  */
  .bg-step-company-1 {
    background-image: url("../public/backgrounds/step_company_1.jpg");
    background-repeat: no-repeat;
    background-position: center;
  }
  .bg-step-company-2 {
    background-image: url("../public/backgrounds/step_company_2.jpg");
    background-repeat: no-repeat;
    background-position: center;
  }
  .bg-step-company-3 {
    background-image: url("../public/backgrounds/step_company_3.jpg");
    background-repeat: no-repeat;
  }
  .bg-step-company-4 {
    background-image: url("../public/backgrounds/step_company_4.jpg");
    background-repeat: no-repeat;
    background-position: center;
  }
  .bg-step-company-5 {
    background-image: url("../public/backgrounds/step_company_5.jpg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

/**
 * uploadcare's default styles for buttons are overridden
 * by tailwind base import.
 */
.uploadcare--widget__button_type_open,
.uploadcare--widget__button_type_open:active,
.uploadcare--widget__button_type_open:focus,
.uploadcare--widget__button_type_open:hover,
.uploadcare--button_primary,
.uploadcare--button_primary:active,
.uploadcare--button_primary:focus,
.uploadcare--button_primary:hover {
  background: #f6b32a;
  border-color: #f6b32a;
  outline-color: #f6b32a;
  color: #fff;
}

.uploadcare--preview__back {
  border-color: currentColor;
  color: #f6b32a;
}

.uploadcare--widget__file-name,
.uploadcare--widget__file-name:active,
.uploadcare--widget__file-name:focus,
.uploadcare--widget__file-name:hover {
  color: #f6b32a;
}
